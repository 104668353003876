export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAIL = 'CREATE_NEW_USER_FAIL';
export const CREATE_NEW_USER_RESET = 'CREATE_NEW_USER_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const MY_PROFILE_DETAILS_REQUEST = 'MY_PROFILE_DETAILS_REQUEST';
export const MY_PROFILE_DETAILS_SUCCESS = 'MY_PROFILE_DETAILS_SUCCESS';
export const MY_PROFILE_DETAILS_FAIL = 'MY_PROFILE_DETAILS_FAIL';
export const MY_PROFILE_DETAILS_RESET = 'MY_PROFILE_DETAILS_RESET';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const CHANGE_STATUS_USER_REQUEST = 'CHANGE_STATUS_USER_REQUEST';
export const CHANGE_STATUS_USER_SUCCESS = 'CHANGE_STATUS_USER_SUCCESS';
export const CHANGE_STATUS_USER_FAIL = 'CHANGE_STATUS_USER_FAIL';
export const CHANGE_STATUS_USER_RESET = 'CHANGE_STATUS_USER_RESET';

export const GET_USER_BY_TOKEN_REQUEST = 'GET_USER_BY_TOKEN_REQUEST';
export const GET_USER_BY_TOKEN_SUCCESS = 'GET_USER_BY_TOKEN_SUCCESS';
export const GET_USER_BY_TOKEN_FAIL = 'GET_USER_BY_TOKEN_FAIL';
export const GET_USER_BY_TOKEN_RESET = 'GET_USER_BY_TOKEN_RESET';

export const CHANGE_PASSWORD_USER_REQUEST = 'CHANGE_PASSWORD_USER_REQUEST';
export const CHANGE_PASSWORD_USER_SUCCESS = 'CHANGE_PASSWORD_USER_SUCCESS';
export const CHANGE_PASSWORD_USER_FAIL = 'CHANGE_PASSWORD_USER_FAIL';
export const CHANGE_PASSWORD_USER_RESET = 'CHANGE_PASSWORD_USER_RESET';

export const UPDATE_PASSWORD_USER_REQUEST = 'UPDATE_PASSWORD_USER_REQUEST';
export const UPDATE_PASSWORD_USER_SUCCESS = 'UPDATE_PASSWORD_USER_SUCCESS';
export const UPDATE_PASSWORD_USER_FAIL = 'UPDATE_PASSWORD_USER_FAIL';
export const UPDATE_PASSWORD_USER_RESET = 'UPDATE_PASSWORD_USER_RESET';

export const CHANGE_ADMIN_REQUEST = 'CHANGE_ADMIN_REQUEST';
export const CHANGE_ADMIN_SUCCESS = 'CHANGE_ADMIN_SUCCESS';
export const CHANGE_ADMIN_FAIL = 'CHANGE_ADMIN_FAIL';
export const CHANGE_ADMIN_RESET = 'CHANGE_ADMIN_RESET';

export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL';
export const ADD_MESSAGE_RESET = 'ADD_MESSAGE_RESET';

export const ADD_NOTES_REQUEST = 'ADD_NOTES_REQUEST';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS';
export const ADD_NOTES_FAIL = 'ADD_NOTES_FAIL';
export const ADD_NOTES_RESET = 'ADD_NOTES_RESET';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';
export const LOGOUT_USER_RESET = 'LOGOUT_USER_RESET';


export const  GET_CONVERSION_RATE_REQUEST = ' GET_CONVERSION_RATE_REQUEST';
export const GET_CONVERSION_RATE_SUCCESS ='GET_CONVERSION_RATE_SUCCESS';
export const GET_CONVERSION_RATE_FAIL ='GET_CONVERSION_RATE_FAIL';
export const GET_CONVERSION_RATE_RESET='GET_CONVERSION_RATE_RESET';    
